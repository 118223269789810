import { IABTest, IPaxshopData, IThirdPartyData } from 'types/init';
import { appConfig } from 'config';
import { captureException } from '@sentry/browser';
import { makeRequest } from '_middlewares/http';
import { googleTagManager, IGTMConfig } from '_helpers/googleTagManager';
import { initHotjar } from '_helpers/hotjar';

export function initPaxshop(paxshopId: number) {
  return {
    type: 'PAXSHOP_INIT',
    paxshopId,
  } as const;
}

export function markAnotherBookingTabLoggedIn() {
  return {
    type: 'PAXSHOP_ANOTHER_BOOKING_TAB_LOGGED_IN',
  } as const;
}

export function fetchInitialPaxshopData() {
  return makeRequest<IPaxshopData>({
    url: `${appConfig.apiUrl}/v4/info/paxshop/${appConfig.paxshopId}`,
    onSuccess: data => dispatch => {
      dispatch(handlePaxshopDataFetched(data));

      try {
        const { abProdMeasurementId, abTestMeasurementId } = appConfig;
        initializeTrackers({
          ...data.thirdPartyData,
          abProdMeasurementId,
          abTestMeasurementId,
        });
      } catch (e) {
        captureException(e);
      }
    },
  });
}

export function handlePaxshopDataFetched(data: IPaxshopData) {
  return {
    type: 'PAXSHOP/FETCH_SUCCESS',
    payload: data,
  } as const;
}

interface ITrackingData extends IABTest, IThirdPartyData {}

function initializeTrackers(trackers: ITrackingData) {
  if (!trackers) {
    return;
  }

  const {
    googleAnalytics,
    googleAnalyticsCommon,
    googleTagManager: googleTagManagerId,
    abProdMeasurementId,
    abTestMeasurementId,
  } = trackers;

  if (googleAnalytics) {
    googleTagManager.streams.customerMeasurementId = googleAnalytics;
  }

  if (googleAnalyticsCommon) {
    googleTagManager.streams.accumulatedMeasurementId = googleAnalyticsCommon;
  }

  // Add A/B-Test measurement id if exist
  const testMeasurementId = /secure/i.test(window.location.origin)
    ? abProdMeasurementId
    : abTestMeasurementId;

  if (abTestMeasurementId) {
    googleTagManager.abTest.testMeasurementId = testMeasurementId;
  }

  if (googleTagManagerId) {
    googleTagManager.gtmId = googleTagManagerId;
  }

  googleTagManager.init();
}
