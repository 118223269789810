import { fetchingContainerDefaultState } from '_helpers/datacontainer/datacontainer';
import { appConfig } from 'config';
import { Md5 } from 'ts-md5';
import { IAuthRes } from 'types/auth';
import localStorageHelper from '_helpers/localStorage';
import { googleTagManager } from '_helpers/googleTagManager';
import { store } from 'store';
import * as actions from '../actions';
import { InferValueTypes, IStoreState } from '../../types/store';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

const defaultState = fetchingContainerDefaultState(false);

const getSiriusBaseUrl = () => {
  // Staging
  if (/staging/i.test(window.location.origin)) {
    return 'https://shop-staging.paxport.se/';
  }

  // Prod
  if (/secure/i.test(window.location.origin)) {
    return 'https://shop.paxport.net/';
  }

  // Default test
  // if (/test/i.test(window.location.origin)) {
  return 'https://shop-test.paxport.se/main/';
  // For dev:
  // return 'http://localhost:5173/';
};

const generateSiriusLink = (
  authRes: Pick<IAuthRes, 'bookingNumber' | 'departureDate' | 'lastName'>
) => {
  const url = new URL(getSiriusBaseUrl() + appConfig.siriusBasePath);
  // TODO lang?
  // Include referenceData?
  (['bookingNumber', 'departureDate', 'lastName'] as const).forEach(k => {
    if (authRes[k]) {
      url.searchParams.append(k, authRes[k]);
    }
  });
  url.searchParams.append('abTest', 'true');
  return url.toString();
};

/**
 * Returns true for a some bookingNumbers.
 * The fraction of true depends on the appConfig.redirectToSirius-config
 * Safe to increase the fraction of bookingNumbers.
 *
 * @param {string} bookingNumber
 * @returns {boolean}
 */
function redirectToSirius(bookingNumber: string): boolean {
  // SQL equivalent: SELECT ('0x'||RIGHT(MD5(UPPER(TRIM(booking_number))), 2))::float::numeric / 255 < redirectToSirius AS UseSirius
  return (
    parseInt(Md5.hashStr(bookingNumber.trim().toUpperCase()).slice(-2), 16) /
      255 <
    appConfig.redirectToSirius
  );
}

export default function authSiriusRedirect(
  state = defaultState,
  action: ActionTypes
) {
  if (action.type === 'PAXSHOP_POST_LOGIN') {
    const isDeeplinkAfterPayment = new URL(
      window.location.href
    ).searchParams.has('orderId');

    // If login triggers redirect to sirius
    if (
      // airline shop
      ((appConfig.redirectToSirius &&
        !appConfig.topShopRedirect &&
        appConfig.tourOperatorAirlineRedirect.includes(
          action.authRes.tourOperatorId
        )) ||
        // top shop
        (appConfig.redirectToSirius &&
          appConfig.topShopRedirect &&
          appConfig.topShopCarrierRedirect.includes(
            action.authRes.carrierCustomerId
          ))) &&
      action.authRes.bookingNumber &&
      redirectToSirius(action.authRes.bookingNumber)
    ) {
      localStorageHelper.resetAllButLanguage();
      window.location.href = generateSiriusLink(action.authRes);
    }
    // If login was not after payment
    else if (
      // airline shop
      ((appConfig.redirectToSirius &&
        !appConfig.topShopRedirect &&
        appConfig.tourOperatorAirlineRedirect.includes(
          action.authRes.tourOperatorId
        )) ||
        // top shop
        (appConfig.redirectToSirius &&
          appConfig.topShopRedirect &&
          appConfig.topShopCarrierRedirect.includes(
            action.authRes.carrierCustomerId
          ))) &&
      !isDeeplinkAfterPayment
    ) {
      googleTagManager.abTest.sendData = true;

      googleTagManager.abTestEvent({
        event: 'ab_test_gtm',
        testMeasurementId: googleTagManager.abTest.testMeasurementId,
      });

      googleTagManager.abTestEvent({
        event: 'login',
        loginMethod: 'paxshop_2.0',
      });

      googleTagManager.abTestEvent({
        event: 'experiment',
        experimentVariant: 'original',
        experimentName: appConfig.experimentName,
      });

      googleTagManager.abTestEvent(
        {
          event: 'init_hotjar',
          hotjarId: /secure/i.test(window.location.origin)
            ? appConfig.hotjarIdProd
            : appConfig.hotjarIdTest,
        },
        false
      );
    }
    // If login was after payment
    else if (
      ((appConfig.redirectToSirius &&
        !appConfig.topShopRedirect &&
        appConfig.tourOperatorAirlineRedirect.includes(
          action.authRes.tourOperatorId
        )) ||
        // top shop
        (appConfig.redirectToSirius &&
          appConfig.topShopRedirect &&
          appConfig.topShopCarrierRedirect.includes(
            action.authRes.carrierCustomerId
          ))) &&
      isDeeplinkAfterPayment
    ) {
      googleTagManager.abTest.sendData = true;

      googleTagManager.abTestEvent({
        event: 'ab_test_gtm',
        testMeasurementId: googleTagManager.abTest.testMeasurementId,
      });

      googleTagManager.abTestEvent({
        event: 'experiment',
        experimentVariant: 'original',
        experimentName: appConfig.experimentName,
      });

      googleTagManager.abTestEvent(
        {
          event: 'init_hotjar',
          hotjarId: /secure/i.test(window.location.origin)
            ? appConfig.hotjarIdProd
            : appConfig.hotjarIdTest,
        },
        false
      );
    }
  }

  return state;
}
