/* eslint-disable @typescript-eslint/no-explicit-any */
// Source: https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce
// TODO: Types might not be 100% correct.

export type IGTMProduct = {
  /** Name or ID is required */ name?: string;
  /** Name or ID is required Eg. 12345 */ id?: string | number;
  /** Eg. 15.25 */ price?: string | number;
  /** Eg. Google */ brand?: string;
  /** Eg. Apparel */ category?: string;
  /** Eg. Gray */ variant?: string;
} & ({ name: string } | { id: string | number }) & {
    // TODO: Replace with Record<`dimension${number}, string>
    dimension1?: string;
    dimension2?: string;
    dimension3?: string;
    dimension4?: string;
    dimension5?: string;
    dimension6?: string;
  } & {
    // TODO: Replace with Record<`metric${number}, string>
    metric1?: string;
    metric2?: string;
    metric3?: string;
    metric4?: string;
    metric5?: string;
    metric6?: string;
  };

export type IGTMProductWithPosition = IGTMProduct & {
  position?: number;
};
export type IGTMProductWithList = IGTMProductWithPosition & {
  /** Eg. Search Results */ list?: string;
};

export type IGTMProductWithQuantity = IGTMProduct & {
  quantity: number;
};

export type IGTMProductWithCoupon = IGTMProductWithQuantity & {
  /** Optional fields may be omitted or set to empty string. */
  coupon?: string | '';
};

export type IGTMPromotion = {
  id?: string | number;
  name?: string;
  creative?: string;
  position?: string;
} & ({ name: string } | { id: string | number });

export type IGTMEvent = {
  event?: string;
  ecommerce?: {
    /** Local currency is optional */ currencyCode?: string;
    /** Measures product impressions and also tracks a standard
     * pageview for the tag configuration.
     * Product impressions are sent by pushing an impressions object
     * containing one or more impressionFieldObjects. */
    impressions?: IGTMProductWithList[];
    /**
     * Call this function when a user clicks on a product link. This function uses the event
     * callback datalayer variable to handle navigation after the ecommerce data has been sent
     * to Google Analytics.
     */
    click?: {
      actionField?: { list: string }; // Optional list property.
      products: IGTMProductWithPosition[];
    };
    /** Measure a view of product details. This example assumes the detail view occurs on pageload,
     * and also tracks a standard pageview of the details page.
     */
    detail?: {
      actionField?: { list: string }; // Optional list property.
      products: IGTMProduct[];
    };
    /**  Measure adding a product to a shopping cart by using an 'add' actionFieldObject
     * and a list of productFieldObjects. */
    add?: {
      products: IGTMProductWithQuantity[];
    };
    /** Measure the removal of a product from a shopping cart. */
    remove?: {
      products: IGTMProductWithQuantity[];
    };
    promoView?: {
      promotions: IGTMPromotion[];
    };
    promoClick?: {
      promotions: IGTMPromotion[];
    };
    checkout?: {
      actionField?: { step: number; option?: string };
      products: IGTMProductWithQuantity[];
    };
    checkout_option?: {
      actionField: { step: number; option: string };
    };
    purchase?: {
      actionField: {
        /** Transaction ID. Required for purchases and refunds. */ id:
          | string
          | number;
        /** Eg. Online Store */ affiliation?: string;
        /** Total transaction value (incl. tax and shipping) Eg. 35.43 */ revenue?:
          | string
          | number;
        /** Eg. 4.90 */ tax?: string | number;
        /** Eg. 5.99 */ shipping?: string | number;
        coupon?: string;
      };
      products: IGTMProductWithCoupon[];
    };
    refund?: {
      actionField: {
        /** Transaction ID. Required for purchases and refunds. */ id: string;
      };
      /** Product ID and quantity. Required for partial refunds. */
      products?: IGTMProductWithQuantity[];
    };
    eventCallback?: () => void;
  };
};
export interface IGTMConfig {
  gtmId: string;
  layerId?: string;
  /** If set, the tag will be fired once for every object in the array,
   * with the object added to the dataLayser object.
   * This makes it possible to fire with multiple Google Analytics Id. */
  multiFire?: Record<string, string>[];
  hotjarId?: number;
}
interface IGTMConfigUsed extends IGTMConfig {
  layerId: string;
}

class GoogleTagManagerService {
  streams: {
    accumulatedMeasurementId?: string;
    customerMeasurementId?: string;
  } = {};

  abTest: {
    testMeasurementId?: string;
    sendData?: boolean;
  } = {};

  gtmId?: string;

  public init(): void {
    window['dataLayer'] = window['dataLayer'] || [];

    window['dataLayer'].push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
      ...this.streams,
    });

    // Download and inject GTM script at the top of the <head> section
    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${this.gtmId}`;
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(gtmScript, firstScriptTag);
  }

  public pushToDataLayer(
    event: IGTMEvent,
    customProperties?: Record<string, string | number>
  ): void {
    const streams: Record<string, string> = { ...this.streams };

    if (this.abTest.sendData) {
      streams.testMeasurementId = this.abTest.testMeasurementId;
    }

    Object.values(streams).forEach(measurementId => {
      window['dataLayer'].push({ ecommerce: null }); // Clear the previous ecommerce object.
      window['dataLayer'].push({
        ...event,
        ...customProperties,
        measurementId,
      });
      window['dataLayer'].push({ ecommerce: null }); // Clear ecommerce object (for next event).
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public abTestEvent(data: Record<string, any>, includeId = true) {
    const payload = { ...data };

    if (this.abTest.testMeasurementId && includeId) {
      payload.measurementId = this.abTest.testMeasurementId;
    }

    window['dataLayer'].push(payload);
  }
}

export const googleTagManager = new GoogleTagManagerService();
